<script setup lang="ts">
import { OnClickOutside } from "@vueuse/components";
import { useMagicKeys } from "@vueuse/core";

const emits = defineEmits(["addEmoji", "close"]);

const emojis = useEmojiStore();
const { shift, ctrl, meta } = useMagicKeys();

const search = ref("");

const emojisFiltered = computed(() => {
    return emojis.emojis.filter(emoji => {
        return (
            emoji.name.toLowerCase().includes(search.value.toLowerCase()) ||
            emoji.code.includes(search.value.toLowerCase())
        );
    });
});

const isPressed = computed(() => {
    return shift.value || ctrl.value || meta.value;
});

const addEmoji = (emoji: string) => {
    emits("addEmoji", emoji, !isPressed.value);
};
</script>

<template>
    <OnClickOutside
        :options="{ ignore: ['#emojiPicker'] }"
        @trigger="emits('close')"
    >
        <div
            class="absolute top-0 z-50 -mt-2 flex max-h-32 min-h-32 w-full -translate-y-full flex-col overflow-hidden overflow-y-auto rounded-xl bg-main p-2 shadow-xl"
        >
            <div class="grid grid-cols-9">
                <button
                    v-for="(v, k) in emojisFiltered"
                    :key="k"
                    class="flex justify-center items-center rounded-lg bg-main p-1 hover:bg-[#50A0EB]"
                    @click="addEmoji(v.code)"
                >
                    <img :src="v.path" :alt="v.name">
                </button>
            </div>
        </div>
    </OnClickOutside>
</template>
