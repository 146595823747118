<script setup lang="ts">
import { useScroll } from "@vueuse/core";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

const props = defineProps({
    data: {
        type: Array as PropType<ChatMessageInterface[]>,
        required: true
    }
});

const sortedMessages = computed(() => props.data);

const el = ref();
const chatBodyScroll = useScroll(el);
const checkScrolledEnd = ref(false);

const emits = defineEmits(["delete", "mute", "reply", "go-to-reply"]);

const onResize = () => {
    // console.log("resize");
};

const onUpdate = () => {
    console.log("update");
};

const onScrollEnd = () => {};

defineExpose({
    scrollToBottom() {
        // setTimeout(() => {
        el.value?.scrollToBottom();
        // }, 1000);
    },
    el
});
</script>

<template>
    <DynamicScroller
        ref="el"
        :items="sortedMessages"
        :min-item-size="54"
        :emit-update="false"
        class="scroller overflow-transparent"
        :buffer="100"
        @resize="onResize"
        @update="onUpdate"
        @scroll-end="onScrollEnd"
        key-field="id"
    >
        <template #default="{ item, index, active }">
            <DynamicScrollerItem
                :item="item"
                :active="active"
                :size-dependencies="[item.content]"
                :data-index="index"
                :data-active="active"
                class="py-2"
            >
                <slot :message="item" :key="item.id" />
            </DynamicScrollerItem>
        </template>
    </DynamicScroller>
</template>

<style scoped>
.chat-demo {
    overflow: hidden;
    flex: auto 1 1;
    display: flex;
    flex-direction: column;
}

.scroller {
    flex: auto 1 1;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
